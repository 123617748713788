/* eslint-disable react/no-array-index-key */
/**
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, css } from 'styled-components';
import L from 'leaflet';
import { Marker, useMap, useMapEvent } from 'react-leaflet';

const roundMarkerStyle = css`
  border: 1px solid black;
  color: black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const TimeMarkerStyles = createGlobalStyle`
  .time-marker {
    border: 1px solid black;
    color: black;
    background-color: yellow;
    width: auto !important;
    padding: 1px 1px;
    text-align: center;
    min-width: 12px;
    font-size: 10px;
    line-height: 1;
  }
  .start-marker {
    ${roundMarkerStyle};
    background-color: green;
  }
  .finish-marker {
    ${roundMarkerStyle};
    background-color: red;
  }
`;

const startIcon = L.divIcon({ className: 'start-marker' });
const finishIcon = L.divIcon({ className: 'finish-marker' });

function TimeMarkers(props) {
  const { points, start, finish } = props;
  console.log(props);
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());
  useMapEvent('zoomend', () => {
    setZoom(map.getZoom());
  });
  const startStop = (
    <>
      <Marker position={start} icon={startIcon} interactive={false} />
      <Marker position={finish} icon={finishIcon} interactive={false} />
    </>
  );
  if (zoom < 20) {
    return startStop;
  }
  const pointMarkers = points.map((point) => {
    const { time, latlng } = point;
    const icon = L.divIcon({
      className: 'time-marker',
      html: time,
    });
    return <Marker position={latlng} icon={icon} interactive={false} />;
  });
  return [startStop, pointMarkers];
}

TimeMarkers.propTypes = {
  points: PropTypes.array.isRequired,
  start: PropTypes.array.isRequired,
  finish: PropTypes.array.isRequired,
};

export default TimeMarkers;
