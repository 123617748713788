/**
 *
 * urlUtils
 *
 */

export function serialize(obj) {
  if (obj === undefined || obj === null) {
    return '';
  }
  return Object.keys(obj)
    .map((k) => {
      switch (obj[k]) {
        case undefined:
        case null:
        case false:
          return false;
        case true:
          return k;
        default:
      }
      const v = encodeURIComponent(obj[k]);
      return `${k}=${v}`;
    }).filter(Boolean).join('&');
}

function parseValue(val) {
  switch (val) {
    case undefined:
    case 'true':
      return true;
    case 'null':
    case 'false':
      return false;
    default:
  }
  const numVal = Number(val);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(numVal) ? decodeURIComponent(val) : numVal;
}

export function parseString(str) {
  const result = {};
  str.replace(/^[#?]/, '').trim().split('&').forEach((item) => {
    const [key, value] = item.split('=');
    if (key) {
      result[key] = parseValue(value);
    }
  });
  return result;
}
