/**
 * Load file from URL
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

function fetchUrl(url, onChange) {
  onChange({ url, loading: true });
  return axios.get(url)
    .then((response) => {
      // console.log('response', response);
      onChange({ url, response });
    })
    .catch((error) => {
      onChange({ url, error });
    });
}

function LoadFile(props) {
  const { value, onChange } = props;
  const { url } = value;
  const [newUrl, setUrl] = useState(url || '');
  useEffect(() => {
    if (!url) {
      return;
    }
    fetchUrl(url, onChange);
  }, [url, onChange]);
  function handleSubmit(ev) {
    ev.preventDefault();
    fetchUrl(newUrl, onChange);
  }

  return (
    <form className="d-flex" onSubmit={handleSubmit}>
      <input type="url" value={newUrl} onChange={(ev) => setUrl(ev.target.value)} className="form-control mr-3 text-monospace" required />
      <button className="btn btn-primary" type="submit">OK</button>
    </form>
  );
}

LoadFile.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LoadFile;
