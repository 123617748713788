import { useState, useEffect } from 'react';

function useHash() {
  const { addEventListener, removeEventListener } = window;
  function getHash(url) {
    return url.split('#')[1] || '';
  }
  const [hash, setHash] = useState(getHash(window.location.href));
  function newHash(data) {
    setHash(getHash(data.newURL));
  }
  useEffect(() => {
    addEventListener('hashchange', newHash, false);
    return () => removeEventListener('hashchange', newHash, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return hash;
}

export default useHash;
