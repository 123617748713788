import React, { useState } from 'react';
import styled from 'styled-components';
import LoadFile from './components/LoadFile';
import ShowFile from './components/ShowFile';
import { parseString } from './utils/useUrl';

const Wrapper = styled.div`
  .prewrap {
    white-space: pre-wrap;
  }
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  .leaflet-container {
    flex-grow: 1;
  }
`;

function App() {
  const search = parseString(window.location.search);
  const url = search.url || (search.activity && `https://test.runnermaps.nl/api/strava/${search.activity}?format=json`);
  const [file, setFile] = useState({ url });
  return (
    <Wrapper className="container-fluid my-0 py-3">
      <header className="mb-3">
        <h1>Activity Viewer</h1>
        <LoadFile value={file} onChange={setFile} />
      </header>
      <ShowFile file={file} />
    </Wrapper>
  );
}

export default App;
