/**
 *
 * Pagination
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Pagination(props) {
  const {
    value, onChange, length, className,
  } = props;

  function handleKey(event) {
    switch (event.key) {
      case 'ArrowLeft':
        if (value > 0) {
          onChange(value - 1);
        }
        break;
      case 'ArrowRight':
        if (value < length - 1) {
          onChange(value + 1);
        }
        break;
      case 'Home':
        if (value > 0) {
          onChange(0);
        }
        break;
      case 'End':
        if (value < length - 1) {
          onChange(length - 1);
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKey);
    return () => document.removeEventListener('keydown', handleKey);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <nav aria-label="Table navigation" className={className}>
      <ul className="pagination align-items-baseline">
        <li className="page-item">
          <button type="button" className="page-link" onClick={() => onChange(0)} disabled={value === 0}>First</button>
        </li>
        <li className="page-item">
          <button type="button" className="page-link" onClick={() => onChange(value - 1)} disabled={value === 0}>Prev</button>
        </li>
        <li className="page-item">
          <span className="mx-4 text-nowrap">
            {value + 1} / {length}
          </span>
        </li>
        <li className="page-item">
          <button type="button" className="page-link" onClick={() => onChange(value + 1)} disabled={value === length - 1}>Next</button>
        </li>
        <button type="button" className="page-link" onClick={() => onChange(length - 1)} disabled={value === length - 1}>Last</button>
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  value: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
