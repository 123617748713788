/**
 * Load file from URL
 */

import React from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import Table from './Table';
import Tabs from './Tabs';

function ShowFile(props) {
  const { file } = props;
  const { loading, error, response } = file;
  if (loading) {
    return <div className="alert alert-info mb-0">Loading file...</div>;
  }
  if (error) {
    return <div className="alert alert-danger mb-0">{error.message}</div>;
  }
  if (!response) {
    return null;
  }
  const { data, headers } = response;
  if (headers['content-type'] !== 'application/json') {
    return <div className="alert alert-danger mb-0">Must be a JSON file</div>;
  }
  const tabs = [
    { key: 'table', Component: Table },
    { key: 'map', Component: Map },
  ];
  return <Tabs tabs={tabs} data={data} />;
}

ShowFile.propTypes = {
  file: PropTypes.object.isRequired,
};

export default ShowFile;
