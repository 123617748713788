import getPositions from './getPositions';

function splitData(data) {
  if (data.length === 0 || data[0].valid === undefined) {
    return null;
  }
  const course = [];
  const exceptions = [];
  let start = 0;
  let status = true;
  const positions = getPositions(data);
  for (let i = 0; i <= data.length; i++) {
    if (i === data.length || data[i].valid !== status) {
      if (status) {
        course.push({ pathOptions: { color: '#3388ff', opacity: 0.5 }, positions: positions.slice(start, i) });
        start = i - 1;
      } else {
        const iMax = i === data.length ? i - 1 : i;
        exceptions.push({ pathOptions: { color: 'red', weight: 5 }, positions: positions.slice(start, iMax + 1) });
        exceptions.push({ pathOptions: { color: 'green' }, positions: [positions[start], positions[iMax]] });
        start = i;
      }
      status = !status;
    }
  }
  return [...course, ...exceptions];
}

export default splitData;
