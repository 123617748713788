/* eslint-disable react/no-array-index-key */
/**
 * View file from URL as Tab;e
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import L from 'leaflet';
import {
  MapContainer, TileLayer, Polyline, LayersControl, ScaleControl,
} from 'react-leaflet';
import splitData from './splitData';
import getPositions from './getPositions';
import TimeMarkers, { TimeMarkerStyles } from './TimeMarkers';
import aroundInvalid from './aroundInvalid';

function Map(props) {
  const { data } = props;
  const positions = getPositions(data);
  const lines = splitData(data) || [{ positions }];
  const points = aroundInvalid(data);
  const bounds = L.latLngBounds(positions);
  return (
    <MapContainer bounds={bounds}>
      <TimeMarkerStyles />
      <ScaleControl position="bottomright" />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Stadia Maps">
          <TileLayer
            attribution='<a href="https://www.stadiamaps.com/">&copy; Stadia Maps</a>&nbsp;<a href="https://openmaptiles.org/">&copy; OpenMapTiles</a>&nbsp;<a href="https://www.openstreetmap.org/about/">&copy; OpenStreetMap contributors</a>'
            url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}.png"
            maxZoom="23"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            attribution="Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Aerogrid, IGN, IGP, and the GIS User Community"
            url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxZoom="23"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom="23"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {lines.map((line, i) => (
        <Polyline key={i} {...line} />
      ))}
      <TimeMarkers points={points} start={positions[0]} finish={positions[positions.length - 1]} />
    </MapContainer>
  );
}

Map.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Map;
