/* eslint-disable react/jsx-props-no-spreading */
/**
 * Tabs
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import useHash from '../hooks/useHash';

function Tabs(props) {
  const { tabs, ...rest } = props;
  const hash = useHash();
  const view = hash || tabs[0].key;
  return (
    <>
      <ul className="nav nav-tabs">
        {tabs.map((tab) => tab.key).map((v) => (
          <li key={v} className="nav-item">
            <a className={`nav-link text-capitalize ${view === v ? 'active' : ''}`} href={`#${v}`}>{v}</a>
          </li>
        ))}
      </ul>
      {tabs.map(({ key, Component }) => (
        view === key && <Component key={key} {...rest} />
      ))}
    </>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default Tabs;
