/**
 * Pagination Hook
 */

import React, { useState } from 'react';
import Pagination from './index';

function usePagination(totalCount, limit = 10, onChange) {
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(totalCount / limit);
  const offset = page * limit;
  function slice(items) {
    return items.slice(offset, offset + limit);
  }
  function handleChange(p) {
    setPage(p);
    if (onChange) {
      onChange(p);
    }
  }
  function Paginator(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return pageCount > 1 && <Pagination value={page} length={pageCount} onChange={handleChange} {...props} />;
  }
  return {
    slice,
    offset,
    Paginator,
    setPage,
    page,
  };
}

export default usePagination;
