/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/**
 * View file from URL as Tab;e
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import usePagination from '../Pagination/usePagination';
import binSearch from './binSearch';

const fields = [
  { key: 'time' },
  { key: 'distance', prec: 1 },
  { key: 'altitude', prec: 1 },
  { key: 'lat', field: 'latlng|0', prec: 6 },
  { key: 'lng', field: 'latlng|1', prec: 6 },
  { key: 'valid' },
  { key: 'originalDistance', prec: 1 },
  { key: 'rowStep', prec: 2 },
  { key: 'geoStep', prec: 2 },
  { key: 'angle', prec: 1 },
];

const Wrapper = styled.table`
  th {
    text-align: center;
    min-width: 6em;
  }
  td {
    text-align: right;
  }
`;

const PAGESIZE = 20;

function Table(props) {
  const { data } = props;
  const {
    slice,
    // offset,
    Paginator,
    setPage,
    // page,
  } = usePagination(data.length, PAGESIZE);
  const keys = fields.map((f) => f.key);

  function getValue(f, row) {
    const v = f.field ? f.field.split('|').reduce((acc, k) => acc[k], row) : row[f.key];
    if (v === undefined || v === null) {
      return v;
    }
    return f.prec ? v.toFixed(f.prec) : v.toString();
  }
  const max = data[data.length - 1].time;

  function findTime(time) {
    const i = binSearch(data.map((r) => r.time), time);
    setPage(Math.floor(i / PAGESIZE));
  }
  function handleSubmit(ev) {
    ev.preventDefault();
    const time = Number(ev.target.elements.time.value);
    findTime(time);
  }
  const invalids = data.filter((r, i) => r.valid === false && !(data[i - 1]?.valid === false));
  return (
    <div className="card">
      <div className="d-flex p-3 align-items-baseline">
        <form className="form-inline align-items-baseline" onSubmit={handleSubmit}>
          <label htmlFor="time">Time</label>
          <input type="number" className="form-control mb-2 ml-2 form-control-sm" id="time" min={0} max={max} step={1} />
        </form>
        {invalids.length > 0 && <div className="ml-3">Invalid Rows:</div>}
        <div>
          {invalids.map((r) => <button key={r.time} type="button" className="btn btn-link text-danger mr-3" onClick={() => findTime(r.time)}>{r.time}</button>)}
        </div>
      </div>
      <Wrapper className="table table-bordered table-hover table-responsive table-sm small">
        <thead>
          <tr>
            {keys.map((key) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody className="text-monospace">
          {slice(data).map((row, i) => (
            <tr key={[row.time, i]} className={row.valid === false ? 'text-danger' : ''}>
              {fields.map((f) => (
                <td key={f.key}>{getValue(f, row)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Wrapper>
      <Paginator className="d-flex justify-content-center" />
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Table;
